<template>
  <modal class-name="w-11/12 sm:w-480 pt-12" ref="modal">
    <div class="mb-10 text-2xl font-bold text-invest-primary">
      Add Bank Account
    </div>
    <form @submit.prevent="addAccount">
      <form-input
        placeholder="Account number"
        name="account_number"
        :form="form"
        v-model="form.data.account_number.value"
      />
      <form-input
        type="select"
        :options="bankOptions"
        defaultValue="Select Bank"
        name="bank_name"
        :form="form"
        v-model="form.data.bank_name.value"
      />
      <div class="mt-32">
        <button type="submit" class="btn btn-primary" :disabled="form.loading">
          Add Account
        </button>
      </div>
    </form>
  </modal>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  SetupContext,
} from 'vue';
import resource from '@/modules/resource';
import investmentModule from '@/modules/investment/investmentModule';
import { addBankDetails } from '@/modules/requests';
import { AxiosError } from 'axios';
export default defineComponent({
  emits: ['bankAccounts'],
  setup: (_props, { emit }: SetupContext) => {
    const modal = ref<any>(null);

    const open: any = () => modal.value.open();

    const { basicForm, getFormData, mapFormErrors } = resource();
    const { bankOptions, allBanks } = investmentModule();

    const data = reactive({
      form: basicForm([
        {
          name: 'account_number',
          rules: 'required|number',
        },
        {
          name: 'bank_name',
          rules: 'required',
        },
      ]),
    });

    onBeforeMount(allBanks);

    const addAccount = () => {
      data.form.loading = true;
      addBankDetails(getFormData(data.form))
        .then(() => {
          emit('bankAccounts');
          modal.value.close();
        })
        .catch((error: AxiosError) => {
          (data.form.error = error?.response?.data?.message),
            mapFormErrors(data.form, error?.response?.data?.errors);
        })
        .finally(() => (data.form.loading = false));
    };

    return { ...toRefs(data), modal, open, bankOptions, addAccount };
  },
});
</script>
