
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  SetupContext,
} from 'vue';
import resource from '@/modules/resource';
import investmentModule from '@/modules/investment/investmentModule';
import { addBankDetails } from '@/modules/requests';
import { AxiosError } from 'axios';
export default defineComponent({
  emits: ['bankAccounts'],
  setup: (_props, { emit }: SetupContext) => {
    const modal = ref<any>(null);

    const open: any = () => modal.value.open();

    const { basicForm, getFormData, mapFormErrors } = resource();
    const { bankOptions, allBanks } = investmentModule();

    const data = reactive({
      form: basicForm([
        {
          name: 'account_number',
          rules: 'required|number',
        },
        {
          name: 'bank_name',
          rules: 'required',
        },
      ]),
    });

    onBeforeMount(allBanks);

    const addAccount = () => {
      data.form.loading = true;
      addBankDetails(getFormData(data.form))
        .then(() => {
          emit('bankAccounts');
          modal.value.close();
        })
        .catch((error: AxiosError) => {
          (data.form.error = error?.response?.data?.message),
            mapFormErrors(data.form, error?.response?.data?.errors);
        })
        .finally(() => (data.form.loading = false));
    };

    return { ...toRefs(data), modal, open, bankOptions, addAccount };
  },
});
